import type { NextPage } from 'next'

import React from 'react'
import Head from 'next/head'
import NextLink from 'next/link'
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { APP_DISPLAY_NAME } from '@/configs/constants'

// import { gtm } from '../lib/gtm';

const NotFound: NextPage = () => {
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  // React.useEffect(() => {
  //   gtm.push({ event: 'page_view' });
  // }, []);

  return (
    <>
      <Head>
        <title>Error: Not Found | {APP_DISPLAY_NAME}</title>
      </Head>
      <Box
        component='main'
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          flexGrow: 1,
          py: '80px'
        }}
      >
        <Container maxWidth='lg'>
          <Typography align='center' variant={mobileDevice ? 'h4' : 'h1'}>
            404: Not Found
          </Typography>
          <Typography
            align='center'
            color='textSecondary'
            sx={{ mt: 0.5 }}
            variant='subtitle2'
          >
            The resource you have requested does not exist.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}
          >
            <Box
              alt='Under development'
              component='img'
              src={`/static/error/error404_${theme.palette.mode}.svg`}
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}
          >
            <NextLink href='/dashboard' passHref>
              <Button component='a' variant='outlined'>
                Back to Dashboard
              </Button>
            </NextLink>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default NotFound
